import React, { useState, useEffect, useRef } from "react";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { NavDropdown } from "react-bootstrap";
import { CSVLink } from "react-csv";

import { FormatValue } from "../../helpers/DataFormatHelpers";

import { GetDataWithCleanedDates } from "../../helpers/DateHelpers";

import { GetExportFileNameDated } from "./ExportFileName";

export default function ServerGridExport(props) {
  const {
    exportData,
    exportDataColumns,
    exportFileNamePrefix,
    getAllExportDataCallback, //This is the callback method top parent that will that will get the data from the server
    exportCompletedCallback, //This is the callback method that will be called when the export is completed
    realFullExportData, //this is the full dataset that will be exported,  the exportData is the filtered dataset and will be used only to set visibility and columns
    exportFormat, //this is the format of the export, either excel or csv
    exportInitiated, //this is a flag that will be set to true when the export is initiated
  } = props;

  const excelExportRef = useRef(null);
  const csvExportRef = useRef(null);

  // const [mainData, setMainData] = useState(exportData);

  const [csvExportData, setCsvExportData] = useState([]);

  //const [excelExportColumns, setExcelExportColumns] = useState();

  const [csvExportColumns, setCsvExportColumns] = useState();

  //This will clean up the columns that are not needed for the export
  const ProcessExcelColumns = (cols) => {
    // if cols is null or empty, return empty array
    if (!cols || cols.length === 0) return [];

    let result = [];

    //add an array of columns that should not be included in the export
    let excludeColumns = ["id", "selected", "action", "selectedid"];

    //console.log("ProcessExcelColumns", cols);

    cols.forEach((column) => {
      if (column.field === null) return;

      if (column.field === undefined) return;

      if (column.field.trim() === "") return;

      //if column.field is in the excludeColumns array, then skip this column
      if (excludeColumns.includes(column.field.toLowerCase())) return;

      result.push(column);
    });

    return result;
  };

  const ExportExcel = () => {
    getAllExportDataCallback("excel");
    //_mainExport.current.save(exportData, excelExportColumns);
  };

  const CsvExport = () => {
    //alert("csv export");
    // csvExportRef.current.link.click();
    getAllExportDataCallback("csv");
    //getAllExportDataCallback("excel");
    //_mainExport.current.save(exportData, excelExportColumns);
  };

  const FormattedCSVExportData = (sourceData, sourceColumns) => {
    let columnsToFormat = GetColumnsToFormatCsvData(sourceColumns);

    //if there are no columns to format, then return original dataset
    if (!columnsToFormat || columnsToFormat.length === 0) {
      return sourceData;
    }

    let result = [];

    sourceData.map((item) => {
      //make a copy of item so we do not alter the original value
      let thisItem = { ...item };
      columnsToFormat.forEach((column) => {
        let thisKey = column.field;

        const formattedValue = FormatValue(item[thisKey], column.format);
        thisItem = { ...thisItem, [thisKey]: formattedValue };
      });

      result.push(thisItem);
    });

    return result;

    //console.log("formattedData", formattedData);
  };

  //All logic on custom formatting is below, building an array of columns that need to be formatted and the format type tht needs to be applied

  const GetColumnsToFormatCsvData = (sourceColumns) => {
    let result = [];

    if (!sourceColumns || sourceColumns.length === 0) return result;

    sourceColumns.forEach((column) => {
      //if column.filter contains the word "date", then format it as a date
      //ignore case when searching for the word "date"
      if (column.filter && column.filter.toLowerCase().includes("date")) {
        result.push({ field: column.field, format: "date" });

        //go to next foreach iteration
        return;
      }

      //also do the column title check for "date" in the field
      if (column.title && column.title.toLowerCase().includes("date")) {
        result.push({ field: column.field, format: "date" });

        //go to next foreach iteration
        return;
      }
    });

    return result;
  };

  const GetCsvColumns = (sourceColumns) => {
    let result = [];

    if (!sourceColumns || sourceColumns.length === 0) return result;

    // var targetColumns = excelExportColumns.filter(
    //   (t) => t.field != null && t.field != undefined && t.field != ""
    // );

    sourceColumns.forEach((column) => {
      result.push({ label: column.title, key: column.field });
    });

    return result;
  };

  useEffect(() => {
    if (csvExportData && csvExportData.length > 0) {
      csvExportRef.current.link.click();

      setCsvExportData([]);

      ExportHasCompleted();
    }
  }, [csvExportData]);

  useEffect(() => {
    if (!exportInitiated) {
      return;
    }

    if (realFullExportData) {
      let excelColumns = ProcessExcelColumns(exportDataColumns);

      let cleanedData = GetDataWithCleanedDates(realFullExportData);

      if (exportFormat.trim().toLowerCase() === "excel") {
        excelExportRef.current.save(cleanedData, excelColumns);

        return;
      }

      if (exportFormat.trim().toLowerCase() === "csv") {
        let csvColumns = GetCsvColumns(excelColumns);
        setCsvExportColumns(csvColumns);

        let formattedCsvData = FormattedCSVExportData(
          cleanedData,
          excelColumns
        );

        setCsvExportData(formattedCsvData);

        return;
      }

      alert("Export format not recognized");
    }
  }, [realFullExportData, exportInitiated]);

  function ExportHasCompleted() {
    if (exportCompletedCallback) {
      exportCompletedCallback();
    }
  }

  let shouldDisable = !exportData || exportData.length === 0;

  //if exportFilePrefix is null or empty, then use the default name of "NetRxExport", else use the prefix
  let exportFileName = exportFileNamePrefix
    ? exportFileNamePrefix
    : "NetRxExport";

  return (
    <ExcelExport
      ref={excelExportRef}
      fileName={GetExportFileNameDated(exportFileName, "xlsx")}
      onExportComplete={() => {
        ExportHasCompleted();
      }}
    >
      {
        // if shouldDisable is true, opacity is 0.5, else 1  to disable the export buttons and makethe dropdown appera greyed out
      }
      <span style={{ opacity: shouldDisable ? 0.5 : 1 }}>
        <NavDropdown
          title={
            <span>
              <i className="fa fa-download" aria-hidden></i> Download As...
            </span>
          }
          id="basic-nav-dropdown"
          disabled={shouldDisable}
        >
          <NavDropdown.Item onClick={ExportExcel} href="#">
            Excel
          </NavDropdown.Item>

          <NavDropdown.Item onClick={CsvExport} href="#">
            CSV
          </NavDropdown.Item>
        </NavDropdown>

        <CSVLink
          ref={csvExportRef}
          data={csvExportData}
          //filename={"csvExport.csv"}
          filename={GetExportFileNameDated(exportFileName, "csv")}
          className="hidden-element dropdown-item"
          headers={csvExportColumns}
          // onClick={GetExportDataTypes}
        >
          CSV
        </CSVLink>
      </span>
    </ExcelExport>
  );
}
